import { Navigate } from "react-router-dom";
import { checkAuth, login } from "./api";
import { useState } from "react";
import { serverUrl, getHeaders, getAuthHeader } from "./api-client";

export default function Auth(props){
    
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [error, setError] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const validate = (e) =>{
        setError(false);
    };

    if (localStorage.getItem('user')){
        getAuthHeader(getHeaders()).then(headers => {
            fetch(serverUrl + 'accounts/profile/', {method: 'GET', 
                headers: headers})
            .then((res) => {
                if (res.ok){
                    setRedirect('/analytics');
                }            
            })
        })
    }

    const sendFunc = (event) => {
        event.preventDefault();

        fetch(serverUrl + 'accounts/token/', {method: 'POST', 
            headers: getHeaders(), body: JSON.stringify({username: username, password: password}) })
        .then((res) => {
            if (res.ok){
                return res.json();
            }
            setError(true);
        })
        .then((res) => {
            const user = {
                username: username,
                accessToken: res['access'],
                refreshToken: res['refresh'],
            }
            localStorage.setItem('user', JSON.stringify(user));
            setRedirect('/analytics');
        })
    };
    
    if (redirect){
        return <Navigate to={redirect}/>;
    }

    return (
        <section className="page" id="auth">
            <form onChange={validate} className="widget"> 
                <h2>Авторизация</h2>
                {error && <div className="invalid-data">Неверные логин/пароль</div>}
                <div className="input-field">
                    <span>Логин</span>
                    <input value={username} onChange={(e) => {setUsername(e.target.value)}} type="text" placeholder="Login" />
                </div>
                <div className="input-field">
                    <span>Пароль</span>
                    <input value={password} onChange={(e) => {setPassword(e.target.value)}} type="password" placeholder="Password" />
                </div>
                <input className="btn" onClick={sendFunc} id="send-btn" type="submit" value="Войти"/>
            </form>
        </section>
    );
}