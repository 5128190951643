import { Component } from "react";
import Menu from "./menu";
import PopUp from "./pop-up";
import Table from "./table";
import { checkAuth, createAuthRequest } from "./api";
import { getHeaders, getAuthHeader, serverUrl } from "./api-client";
import { Navigate } from "react-router-dom";
import InputField from "./input-field";
import DeleteIcon from '../images/delete.png';
import EditIcon from '../images/edit.png';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

let UTCList = [];

for (let index = 1; index < 13; index++) {
    UTCList.push('GMT-' + index.toString())
}

for (let index = 0; index < 13; index++) {
    UTCList.push('GMT+' + index.toString())
}

let formDataDefault = {
    deliveryPointNumber: {placeholder: '777', label: 'Номер ПВЗ', 
        validate: (v) => /^\d+$/.test(v) ? '': 'Включает только цифры' },
    town: {placeholder: 'Москва', label: 'Город', 
        validate: (v) => /^[а-яА-Я]+.*$/.test(v) ? '': 'Недопустимые символы'},
    address: {placeholder: 'ул. Пушкина, д.16', label: 'Текущий адрес', validate: (v) => {}},
    entity: {placeholder: 'Иванова И.А (ИП)', label: 'Юридическое лицо', 
        validate: (v) => /^[^\d]*$/.test(v) ? '': 'Недопустимые символы'},
    currentTPM: {placeholder: 'Иванов Иван', label: 'Текущий ТРМ', 
        validate: (v) => /^[^\d]*$/.test(v) ? '': 'Недопустимые символы'},
    RD: {placeholder: 'Иванов Иван', label: 'РД', 
        validate: (v) => /^[^\d]*$/.test(v) ? '': 'Недопустимые символы'},
    location: {placeholder: '55.749793, 37.537393', label: 'Координаты входа в ПВЗ', 
        validate: (v) => !/^[0-9]{1,2}\.[0-9]+,.[0-9]{1,3}\.[0-9]+$/.test(v) && 'Невалидные координаты'},
    openingTime: {placeholder: '9:00', value: '9:00', label: 'Время открытия ПВЗ (МСК)', 
        validate: (v) => !/^[0-9]{1,2}:[0-5][0-9]$/.test(v) && 'Невалидное время'},
    closingTime: {placeholder: '21:00', value: '21:00', label: 'Время закрытия ПВЗ (МСК)', 
        validate: (v) => !/^[0-9]{1,2}:[0-5][0-9]$/.test(v) && 'Невалидное время'},
    tgUsername: {placeholder: 'username', label: 'TG ID партнера', validate: (v) => {
            if (!/^[a-zA-Z0-9_-]+$/.test(v)){
                return 'Недопустимые символы';
            }
            if (v.length > 32){
                return 'Максимум 32 символа';
            }
        }},
    timeZone: {placeholder: '', label: 'Местный часовой пояс', value: 'GMT-3', type: 'select', options: UTCList,
        validate: (v) => {}},
}

Object.keys(formDataDefault).forEach(key => {
    if (!formDataDefault[key].value){
        formDataDefault[key].value = '';
    }
    formDataDefault[key].error = '';
});

function magicClone(o) {
    if (Object(o) !== o) return o; // primitive value
    if (typeof o.clone == "function") return o.clone(); // if available use it
    return Object.assign(Object.create(Object.getPrototypeOf(o)), o); // shallow copy
}

function clone(o){
    let clone = {};
    Object.keys(o).forEach(key => {
        clone[key] = magicClone(o[key]);
    })
    return clone;
}


class AddDeliveryPointPopUP extends Component 
{
    constructor(props){
        super(props);
        this.state = {
            formData: clone(formDataDefault),
            modalOpen: false,
            isLoaded: false,
        };
        this.validate = this.validate.bind(this);
        this.addDeliveryPointRequest = this.addDeliveryPointRequest.bind(this);
        this.closePopUp = this.closePopUp.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
        this.setModalOpen = this.setModalOpen.bind(this);
        this.updateDeliveryPoint = this.updateDeliveryPoint.bind(this);
    }

    updateDeliveryPoint(){
        this.setState({modalOpen: true, isLoaded: true})
        const body = {number: this.props.updateDeliveryData[0]}
        getAuthHeader(getHeaders()).then(headers => {
            fetch(serverUrl + 'get-delivery-point-one/', {headers: headers, method: 'POST', body: JSON.stringify(body)})
            .then(res => res.json())
            .then(res => {
                let localFD = clone(formDataDefault)
                localFD.deliveryPointNumber.value = res.number
                localFD.town.value = res.town
                localFD.address.value = res.address
                localFD.entity.value = res.entity
                localFD.currentTPM.value = res.currentTPM
                localFD.RD.value = res.rd
                localFD.location.value = res.latitude.toString() + ', ' + res.longitude.toString()
                localFD.openingTime.value = res.open_time_format
                localFD.closingTime.value = res.close_time_format
                localFD.tgUsername.value = res.tg_username_responsible
                localFD.timeZone.value = res.time_zone.toString().replace('Etc/', '')
                this.setState({formData: localFD})
            })
        })
    }

    updateDeliveryPointRequest(data){
        const requestData = {
            number: data.deliveryPointNumber.value,
            town: data.town.value,
            address: data.address.value,
            rd: data.RD.value,
            open_time: data.openingTime.value,
            close_time: data.closingTime.value,
            currentTPM: data.currentTPM.value,
            entity: data.entity.value,
            latitude: data.location.value.toString().split(',')[0],
            longitude: data.location.value.toString().split(',')[1].trim(),
            tg_username_responsible: data.tgUsername.value,
            time_zone: 'Etc/' + data.timeZone.value.toString()
        };

        getAuthHeader(getHeaders()).then(headers => {
            fetch(serverUrl + 'update-delivery-point/', {
                method: 'PATCH', 
                body: JSON.stringify(requestData),
                headers: headers,
            })
            .then(res => {
                if (res.ok){
                    this.props.updateTable();
                    this.setModalOpen()
                    this.setState({formData: clone(formDataDefault)});
                }else{
                    return res.json();
                }
            })
            .then(res => {
                let formDataLocal = this.state.formData;
                if ('location' in res){
                    formDataLocal.location.error = 'Эти координаты уже существуют';
                }else{
                    formDataLocal.deliveryPointNumber.error = 'Если Вы хотите поменять номер, создайте новую запись'
                }
                this.setState({formData: formDataLocal});
            })
        })
    }

    closePopUp(){
        this.setState({formData: clone(formDataDefault), isLoaded: false})
        this.props.closePopUp()
    }

    setModalOpen(){
        this.setState({modalOpen: !this.state.modalOpen})
    }

    addDeliveryPointRequest(data){  // Создаем ПВЗ
        const requestData = {
            number: data.deliveryPointNumber.value,
            town: data.town.value,
            address: data.address.value,
            rd: data.RD.value,
            open_time: data.openingTime.value,
            close_time: data.closingTime.value,
            currentTPM: data.currentTPM.value,
            entity: data.entity.value,
            latitude: data.location.value.toString().split(',')[0],
            longitude: data.location.value.toString().split(',')[1].trim(),
            tg_username_responsible: data.tgUsername.value,
            time_zone: data.timeZone.value
        };

        getAuthHeader(getHeaders()).then(headers => {
            fetch(serverUrl + 'create-delivery-point/', {
                method: 'POST', 
                body: JSON.stringify(requestData),
                headers: headers,
            })
            .then(res => {
                if (res.ok){
                    this.props.updateTable();
                    this.setState({formData: clone(formDataDefault)});
                    this.setModalOpen()
                }else{
                    return res.json();
                }
            })
            .then(res => {
                let formDataLocal = this.state.formData;
                if (res.number){
                    formDataLocal.deliveryPointNumber.error = 'Этот номер уже существует';
                }
                if (res.longitude || res.latitude){
                    formDataLocal.location.error = 'Эти координаты уже существуют';
                }
                this.setState({formData: formDataLocal});
            })
        })
    }

    validate(){
        let data = this.state.formData;
        const requiredField = (v) => (!v) ? 'Обязательное поле' : '';
        const maxLength = (v) => v.toString().length > 256 ? 'Максимум 256 символов': '';

        Object.keys(data).forEach(key => {
            data[key].error = data[key].validate(data[key].value);
            const requiredErr = requiredField(data[key].value);
            if (requiredErr){data[key].error = requiredErr;}

            const maxLengthErr = maxLength(data[key].value);
            if (maxLengthErr){data[key].error = maxLengthErr;}
        });
        
        let isValid = true;
        Object.keys(data).forEach(key => {
            if (data[key].error){
                isValid = false;    
            }
        });

        this.setState({formData: data});
        if (isValid){
            if (this.props.isUpdateProcess)
                this.updateDeliveryPointRequest(data);
            else
                this.addDeliveryPointRequest(data);   
        }
    }

    onChangeField(key, value){
        let keyData = this.state.formData[key];
        keyData.value = value;
        keyData.error = '';
        this.setState({formData: {...this.state.formData, keyData }});
    }

    render(){

        if (this.props.isUpdateProcess && !this.state.isLoaded){
            this.updateDeliveryPoint();
        }

        return (
            <PopUp 
                header="Добавить запись" 
                open={this.state.modalOpen}
                setOpen={this.setModalOpen}
                actionButtonText="Добавить запись"
                actionButtonClick={this.validate}
                closeButtonClick={this.closePopUp}
                buttonText='Добавить запись' 
                content={
                <div>
                    <form onSubmit={this.validate}>
                        <table id="data-base-pop-up-table">
                            <tr>
                                <td><InputField  data={this.state.formData.deliveryPointNumber} onChange={(e) => this.onChangeField('deliveryPointNumber', e.target.value)} /></td>
                                <td><InputField data={this.state.formData.town} onChange={(e) => this.onChangeField('town', e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td><InputField data={this.state.formData.address} onChange={(e) => this.onChangeField('address', e.target.value)}/></td>
                                <td><InputField data={this.state.formData.entity} onChange={(e) => this.onChangeField('entity', e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td><InputField data={this.state.formData.currentTPM} onChange={(e) => this.onChangeField('currentTPM', e.target.value)}/></td>
                                <td><InputField data={this.state.formData.RD} onChange={(e) => this.onChangeField('RD', e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td><InputField data={this.state.formData.location} onChange={(e) => this.onChangeField('location', e.target.value)}/></td>
                                <td><InputField data={this.state.formData.openingTime} onChange={(e) => this.onChangeField('openingTime', e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td><InputField data={this.state.formData.closingTime} onChange={(e) => this.onChangeField('closingTime', e.target.value)}/></td>
                                <td><InputField data={this.state.formData.tgUsername} onChange={(e) => this.onChangeField('tgUsername', e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td><InputField data={this.state.formData.timeZone} onChange={(e) => this.onChangeField('timeZone', e.target.value)}/></td>                            </tr>
                        </table>
                    </form>
                </div>
                }
            />
        );
    }
}


const TableHeaders = ['Номер ПВЗ', 
    'Город', 'Текущий адрес', 'Юридическое лицо', 
    'Текущий ТРМ', 'РД', 'Координаты входа в ПВЗ', 
    'Открытие', 'Закрытие', 'TG ID партнера'
];

function LoadSection ({height=25, width}){
    return (
        <SkeletonTheme color="#777" highlightColor="#ccc">
            <Skeleton height={height} width={width} />
        </SkeletonTheme>
    );
}

const TableLoadingData = [
    <LoadSection width={50}/>,
    <LoadSection width={80}/>,
    <LoadSection width={80}/>,
    <LoadSection width={80}/>,
    <LoadSection width={80}/>,
    <LoadSection width={100}/>,
    <LoadSection width={80}/>,
    <LoadSection width={80}/>,
    <LoadSection width={80}/>,
    <LoadSection width={80}/>,
];


class DataBase extends Component
{
    constructor(props){
        super(props);
        this.state = {
            height: 200, 
            redirect: false,
            tableData: [TableLoadingData],
            tableHeight: 0,
            updateDeliveryData: false,
            isUpdateProcess: false,
        }
        this.getTableDataRequest = this.getTableDataRequest.bind(this);
        this.deleteDeliveryPointRequest = this.deleteDeliveryPointRequest.bind(this);
        this.updateDeliveryPointRequest = this.updateDeliveryPointRequest.bind(this);
        this.setTableData = this.setTableData.bind(this);
        this.checkAuth = this.checkAuth.bind(this);
        this.closePopUp = this.closePopUp.bind(this)
        this.checkAuth();
    }

    checkAuth(){
        getAuthHeader(getHeaders()).catch(e => {
            this.setState({redirect: '/'})
        }).then(headers => {
            fetch(serverUrl + 'accounts/profile/', {headers: headers, method: 'GET'})
            .then(res => {
                if (res.ok){
                    this.getTableDataRequest();
                }else{
                    this.setState({redirect: '/'});
                }
            })
        })
    }

    deleteDeliveryPointRequest(number){
        const updateTable = function (func){
            return () => func();
        }
        const updateTableFunc = updateTable(this.getTableDataRequest);
        createAuthRequest('delete-delivery-point/', 'POST', {number: number}, (res) => {
            updateTableFunc();
        }, () => {});
    }

    updateDeliveryPointRequest(data){
        this.setState({updateDeliveryData: data})
        console.log('lol-fd');
    }

    closePopUp(){
        this.setState({updateDeliveryData: false})
    }

    setTableData(tableData){
        const tableDataWithButton = [];
        tableData.forEach((value) => {
            let row = value.slice();

            function closureFunc (func, number) {
                return () => func(number);
            }

            const onClickDeleteFunc = closureFunc(this.deleteDeliveryPointRequest, row[0]);
            const onClickUpdateFunc = closureFunc(this.updateDeliveryPointRequest, row);

            row.push(
                <button className="table-button" onClick={onClickUpdateFunc}>
                    <img src={EditIcon}/>
                </button>
            );

            row.push(
                <button className="table-button" onClick={onClickDeleteFunc}>
                    <img src={DeleteIcon}/>
                </button>
            );

            tableDataWithButton.push(row);
        });
        this.setState({tableData: tableDataWithButton});
    }

    getTableDataRequest(){
        // Обновляем данные в таблице
        let tableData = [];

        getAuthHeader(getHeaders()).then(headers => {
            fetch(serverUrl + 'get-delivery-point-list/', {headers: headers, method: 'GET'})
            .then(res => res.json())
            .then(res => {
                res.forEach((value) => {
                    tableData.push([
                        value.number,
                        value.town,
                        value.address,
                        value.entity,
                        value.currentTPM,
                        value.rd,
                        value.latitude.toString() + ', ' + value.longitude.toString(),
                        value.open_time_format,
                        value.close_time_format,
                        value.tg_username_responsible,
                        // value.time_zone,
                    ]);
                    this.setTableData(tableData);
                })
            })
        })
    }

    componentDidMount(){
        const tableHeight = document.getElementById('data-base').clientHeight - 100 - document.getElementById('data-base-header').clientHeight;
        this.setState({tableHeight: tableHeight});
        // setInterval(this.getTableDataRequest, 600);
    }

    render(){
        if (this.state.redirect){
            return <Navigate to={this.state.redirect}/>;
        }

        return (
            <div id="data-base" className="page">
                <Menu page="data-base"/>
                <div id="data-base" className="page-content">
                    <div className="header" id="data-base-header">
                        <h2>База данных</h2>
                        <AddDeliveryPointPopUP closePopUp={this.closePopUp} isUpdateProcess={this.state.updateDeliveryData} updateDeliveryData={this.state.updateDeliveryData} updateTable={this.getTableDataRequest}/>
                    </div>
                    <div className="content">
                        <Table height={this.state.tableHeight} headers={TableHeaders} keyElementName="number" idTable="data-base-id" data={this.state.tableData}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DataBase;