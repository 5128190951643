import Analytics from "./analytics";
import Auth from "./auth";
import DataBase from "./data-base";
import React from "react";
import '../app.scss';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/data-base",
        element: <DataBase />,
    },
    {
        path: "/analytics",
        element: <Analytics />,  
    },
    {
        path: "/",
        element: <Auth />,
    }
]);

export default function App(props){
    return <RouterProvider router={router} />;
}