import { Link, Navigate } from "react-router-dom";
import LogoImg from "../images/exmail.png";
import dbDark from '../images/db-dark.png';
import dbLight from '../images/db-light.png';
import arrowLight from '../images/chevron-right-light.png';
import arrowDark from '../images/chevron-right-dark.png';
import analyticsLight from '../images/analytics-light.png';
import analyticsDark from '../images/analytics-dark.png';
import { useState } from "react";

export default function Menu(props){
    const [redirect, setRedirect] = useState(false);

    const logoutFunc = () => {
        localStorage.removeItem('user');
        setRedirect('/');
    }

    if (redirect){
        return <Navigate to={redirect} />;
    }

    return (
        <div className="menu">
            <div className="top">
                <div className="logo">
                    <img src={LogoImg}/>
                </div>
                <nav className="links">
                    <ol>
                        <li className={props.page === 'data-base' ? 'active': ''}>
                            <Link className="link-a" to={"/data-base"}>
                                <div className="link">
                                    <img src={props.page === 'data-base' ? dbLight: dbDark} className="link-icon"/>
                                    <span>База данных</span>
                                    <img src={props.page === 'data-base' ? arrowLight: arrowDark} className="link-chevron"/>
                                </div>
                            </Link>
                        </li>
                        <li className={props.page === 'analytics' ? 'active': ''}>
                            <Link className="link-a" to={"/analytics"}>
                                <div className="link">
                                    <img src={props.page === 'analytics' ? analyticsLight: analyticsDark} className="link-icon"/>
                                    <span>Аналитика</span>
                                    <img src={props.page === 'analytics' ? arrowLight: arrowDark} className="link-chevron"/>
                                </div>
                            </Link>
                        </li>
                    </ol>
                </nav>
            </div>
            <div className="bottom">
                <button className="logout-button" onClick={logoutFunc}>Выйти</button>
            </div>
        </div>
    );
}