import { useState } from "react";

const startRowsDisplayed = 100; 

function TableRow({rowData, keyElementName}){
    return (
        <tr>
          <td>{rowData.delivery_point_data.entity ? rowData.delivery_point_data.entity : '-'}</td>
          <td>{rowData.delivery_point_data.town ? rowData.delivery_point_data.town : '-'}</td>
          <td>{rowData.delivery_point_data.address ? rowData.delivery_point_data.address : '-'}</td>
          <td>{rowData.date_format ? rowData.date_format : '-'}</td>
          <td>{rowData.open_time_format ? rowData.open_time_format : '-'}</td>
          <td>{rowData.delta_open_time ? rowData.delta_open_time : '-'}</td>
          <td>{rowData.close_time_format ? rowData.close_time_format : '-'}</td>
          <td>{rowData.delta_close_time ? rowData.delta_close_time : '-'}</td>
          <td>{rowData.delta_open_geo ? Math.floor(rowData.delta_open_geo) : '-'}</td>
          <td>{rowData.delta_close_geo ? Math.floor(rowData.delta_close_geo) : '-'}</td>
          <td>{rowData.delivery_point_data.rd ? rowData.delivery_point_data.rd : '-'}</td>
          <td>{rowData.delivery_point_data.currentTPM ? rowData.delivery_point_data.currentTPM : '-'}</td>
        </tr>
    );
}

function TableHeaders({headers}){
    return (
        <tr className="headers">
            {headers.map((header) => <td key={header}>{header}</td>)}
        </tr>
    );
}

export default function AnalyticsTable({headers, data, height, idTable, keyElementName, addComponent=false}){
    // alert(height);
    console.log(data)
    if (data && Object.keys(data).length > 0){
        return (
            <div style={{'height': height + 'px'}} className="table widget">
                {addComponent && addComponent}
                <table id={idTable}>
                    <TableHeaders headers={headers}/>
                    {
                        data.map((row) => <TableRow  rowData={row}/>)                
                    }
                </table>
            </div>
        );
    }else{
        return (
            <div style={{'height': height + 'px'}} className="table widget">
                <span className="not-data-table">Нет данных</span>
            </div>
        );
    }
}