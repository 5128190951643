import { Component } from "react";
import Menu from "./menu";
// import Table from "./table";
import PopUp from "./pop-up";
import LockImg from "../images/lock.png";
import geoImg from "../images/geo.png";
import doorImg from "../images/door.png";
import closeImg from "../images/open.png";
import AnalyticsFilter from "./analytics-filter";
import { Navigate } from "react-router-dom";
import InputField from "./input-field";
import { getAuthHeader, getHeaders, serverUrl } from "./api-client";
import AnalyticsTable from "./analytics-table";

function AnalyticsDataItem({icon, text, value, iconId}){
    return (
        <div className="item">
            <div id={iconId} className="icon"><img src={icon} /></div>
            <div className="data">
                <span className="name">{text}:</span>
                <span className="value">{value}</span>
            </div>
        </div>
    );
}

function AnalyticsData({data}){
    
    return (
        <div className="analytics-data widget" id="analytics-data">
            <AnalyticsDataItem iconId="close-icon" text="Открыто с нарушениями" value={data.openWithViolations !== undefined ? data.openWithViolations : '-'} icon={closeImg} />
            <hr></hr>
            <AnalyticsDataItem iconId="door-icon" text="Ранние закрытия" value={data.earlyClosures !== undefined ? data.earlyClosures: '-'} icon={doorImg} />
            <hr></hr>
            <AnalyticsDataItem iconId="lock-icon" text="Не открыто ПВЗ" value={data.notOpen !== undefined ? data.notOpen : '-'} icon={LockImg} />
            <hr></hr>
            <AnalyticsDataItem iconId="geo-icon" text="Нарушения по гео-точке" value={data.geoViolations !== undefined ? data.geoViolations : '-'} icon={geoImg} />
        </div>
    );
}

let formData = {
    partner: {placeholder: '', label: 'Партнер', value: 'Все',  type: 'select', options: ['Загрузка...', ],
        validate: (v) => false },
    town: {placeholder: '', label: 'Город', value: 'Все', type: 'select', options: ['Загрузка...', ],
        validate: (v) => false},
    address: {placeholder: '', label: 'Текущий адрес', value: 'Все', type: 'select', options: ['Загрузка...', ],
        validate: (v) => false},
    year: {placeholder: '', label: 'Год', value: 'Все', type: 'select', options: ['Загрузка...', ],
        validate: (v) => false},
    TPM: {placeholder: '', label: 'ТРМ', value: 'Все', type: 'select', options: ['Загрузка...', ],
        validate: (v) => false},
    RD: {placeholder: '', label: 'РД', value: 'Все', type: 'select', options: ['Загрузка...', ],
        validate: (v) => false},
    startPeriod: {placeholder: '', label: 'Дата начала периода', type: 'calendar', validate: (v) => {}},
    endPeriod: {placeholder: '', label: 'Дата конца периода', type: 'calendar', validate: (v) => {}},
    openTimeMin: {placeholder: '', value: '0', label: 'От', type: 'text', 
        validate: (v) => /^-{0,1}\d+$/.test(v) ? '': 'Это число'},
    openTimeMax: {placeholder: '', value: '0', label: 'До', type: 'text', 
        validate: (v) => /^-{0,1}\d+$/.test(v) ? '': 'Это число'},
    closeTimeMin: {placeholder: '', value: '0', label: 'От', type: 'text', 
        validate: (v) => /^-{0,1}\d+$/.test(v) ? '': 'Это число'},
    closeTimeMax: {placeholder: '', value: '0', label: 'До', type: 'text', 
        validate: (v) => /^-{0,1}\d+$/.test(v) ? '': 'Это число'},
    geoMin: {placeholder: '', value: '0', label: 'От', type: 'text', 
        validate: (v) => /^-{0,1}\d+$/.test(v) ? '': 'Это число'},
    geoMax: {placeholder: '', value: '0', label: 'До', type: 'text', 
        validate: (v) => /^-{0,1}\d+$/.test(v) ? '': 'Это число'},
};

Object.keys(formData).forEach(key => {
    if (!formData[key].value){
        formData[key].value = '';
    }
    formData[key].error = '';
});

function clone(o){
    const magicClone = (o) => {
        if (Object(o) !== o) return o; // primitive value
        if (typeof o.clone == "function") return o.clone(); // if available use it
        return Object.assign(Object.create(Object.getPrototypeOf(o)), o); // shallow copy    
    };

    let clone = {};
    Object.keys(o).forEach(key => {
        clone[key] = magicClone(o[key]);
    })
    return clone;
}


class AnalyticsPopUp extends Component
{
    constructor(props){
        super(props);
        this.state = {
            formData: clone(formData),
            modalOpen: false,
            refreshPopUp: false,
            isLoading: false,
        };
        this.validate = this.validate.bind(this);
        this.setModalOpen = this.setModalOpen.bind(this);
        this.closePopUp = this.closePopUp.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
        this.getOptionsReport = this.getOptionsReport.bind(this);
        this.sendPoints = this.sendPoints.bind(this);
        this.getOptionsReport();
    }

    sendPoints(type) {
        getAuthHeader(getHeaders()).then(headers => {
            fetch(serverUrl + 'report-points/', {method: 'POST', headers: headers,
                body: JSON.stringify({
                    type: type, 
                    start_period: this.state.formData.startPeriod.value,
                    end_period: this.state.formData.endPeriod.value
                })})
            .then(r => r.json())
            .then(res => {
                this.closePopUp();
                this.setState({modalOpen: false});

            })
        })
    }

    getOptionsReport(){
        getAuthHeader(getHeaders()).then(headers => {
            fetch(serverUrl + 'get-filter-options/', {method: 'GET', headers: headers})
            .then(res => res.json())
            .then(res => {
                let formDataLocal = clone(this.state.formData);
                res.town_list.unshift('Все');
                res.tpm_list.unshift('Все');
                res.rd_list.unshift('Все');
                res.address_list.unshift('Все');
                res.entity_list.unshift('Все');
                // let yearOptionsList = [];
// 
                // for (let y = 2023; y <= new Date().getFullYear(); y++) {
                    // yearOptionsList.push(y);
                // }

                formDataLocal.town.options = res.town_list;
                formDataLocal.TPM.options = res.tpm_list;
                formDataLocal.RD.options = res.rd_list;
                formDataLocal.address.options = res.address_list;
                formDataLocal.partner.options = res.entity_list;
                // formDataLocal.year.options = yearOptionsList;
                // formDataLocal.year.value = new Date().getFullYear();
                
                this.setState({formData: formDataLocal, isLoading: true , refreshPopUp: !this.state.refreshPopUp});
            })
        });
    }

    closePopUp(){
        this.setState({formData: clone(formData)})
        this.getOptionsReport();
    }

    setModalOpen(){
        this.setState({modalOpen: !this.state.modalOpen})
    }

    validate(){
        let data = this.state.formData;
        let isValid = true;
        Object.keys(data).forEach(key => {
            data[key].error = data[key].validate(data[key].value);
            if (data[key].error){
                isValid = false;
            }
        });
        if (isValid){
            getAuthHeader(getHeaders()).then(headers => {
                fetch(serverUrl + 'create-report/', {headers: headers, method: 'POST', 
                    body: JSON.stringify({
                        partner: this.state.formData.partner.value,
                        town: this.state.formData.town.value,
                        address: this.state.formData.address.value,
                        tpm: this.state.formData.TPM.value,
                        rd: this.state.formData.RD.value,
                        start_period: this.state.formData.startPeriod.value,
                        end_period: this.state.formData.endPeriod.value,
                        open_time_min: this.state.formData.openTimeMin.value,
                        open_time_max: this.state.formData.openTimeMax.value,
                        close_time_min: this.state.formData.closeTimeMin.value,
                        close_time_max: this.state.formData.closeTimeMax.value,
                        geo_min: this.state.formData.geoMin.value,
                        geo_max: this.state.formData.geoMax.value
                    })})
                .then(res => res.json())
                .then(res => {
                    this.setModalOpen()
                    this.closePopUp()
                })
            })
        }else{
            this.setState({formData: data})
        }
    }

    onChangeField(key, value){
        let keyData = this.state.formData[key];
        keyData.value = value;
        keyData.error = '';
        this.setState({formData: {...this.state.formData, keyData}});
    }

    render(){
        return (
            <PopUp 
                header="Вывод отчета" 
                actionButtonText="Вывод в Google Sheets"
                actionButtonClick={this.validate}
                open={this.state.modalOpen}
                setOpen={this.setModalOpen}                
                closeButtonClick={this.closePopUp}
                buttonText='Вывод отчета' 
                content={
                <div>
                    <form onSubmit={this.validate}>
                        <table id="analytics-pop-up-table">
                            <tr>
                                <td><InputField refresh={this.state.refreshPopUp}  data={this.state.formData.partner} onChange={(e) => this.onChangeField('partner', e.target.value)}/></td>
                                <td><InputField refresh={this.state.refreshPopUp} data={this.state.formData.town} onChange={(e) => this.onChangeField('town', e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td><InputField refresh={this.state.refreshPopUp} data={this.state.formData.address} onChange={(e) => this.onChangeField('address', e.target.value)}/></td>
                                {/* <td><InputField refresh={this.state.refreshPopUp} data={this.state.formData.year} onChange={(e) => this.onChangeField('year', e.target.value)}/></td> */}
                            </tr>
                            <tr>
                                <td><InputField refresh={this.state.refreshPopUp} data={this.state.formData.TPM} onChange={(e) => this.onChangeField('TPM', e.target.value)}/></td>
                                <td><InputField refresh={this.state.refreshPopUp} data={this.state.formData.RD} onChange={(e) => this.onChangeField('RD', e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td><InputField refresh={this.state.refreshPopUp} data={this.state.formData.startPeriod} onChange={(e) => this.onChangeField('startPeriod', e.target.value)}/></td>
                                <td><InputField refresh={this.state.refreshPopUp} data={this.state.formData.endPeriod} onChange={(e) => this.onChangeField('endPeriod', e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td >
                                    <span>Отклонение по открытию</span>
                                    <div className="td-horizontal">
                                        <InputField className="short" refresh={this.state.refreshPopUp} data={this.state.formData.openTimeMin} onChange={(e) => this.onChangeField('openTimeMin', e.target.value)}/>
                                        <InputField className="short" refresh={this.state.refreshPopUp} data={this.state.formData.openTimeMax} onChange={(e) => this.onChangeField('openTimeMax', e.target.value)}/>
                                    </div>
                                </td>
                                <td >
                                    <span>Отклонение по закрытию</span>
                                    <div className="td-horizontal">
                                        <InputField refresh={this.state.refreshPopUp} data={this.state.formData.closeTimeMin} onChange={(e) => this.onChangeField('closeTimeMin', e.target.value)}/>
                                        <InputField refresh={this.state.refreshPopUp} data={this.state.formData.closeTimeMax} onChange={(e) => this.onChangeField('closeTimeMax', e.target.value)}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <span>Отклонение по гео-точке</span>
                                    <div className="td-horizontal">
                                        <InputField refresh={this.state.refreshPopUp} data={this.state.formData.geoMin} onChange={(e) => this.onChangeField('geoMin', e.target.value)}/>
                                        <InputField refresh={this.state.refreshPopUp} data={this.state.formData.geoMax} onChange={(e) => this.onChangeField('geoMax', e.target.value)}/>
                                    </div>
                                </td>
                                <td >
                                    <span >Вывести только:</span>
                                    <div style={{"display": "flex", "marginTop" : "20px", "flexFlow": "space-between"}}>
                                        <button className="btn" style={{"width": "45%", "boxSizing" : "borderBox", "margin": "0", "margin-right" : "5px"}} onClick={(e) => {e.preventDefault(); this.sendPoints("open")}}>Открытые точки</button>
                                        <button className="btn" style={{"width": "45%", "boxSizing" : "borderBox", "margin": "0"}} onClick={(e) =>{ e.preventDefault(); this.sendPoints("close")}} >Неоткрытые точки</button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>} />
        );
    }
}


class Analytics extends Component
{
    constructor(props){
        super(props);
        this.state = {
            redirect: false,
            analyticsData: {},
            shortAnalytics: {},
            // rawAnalyticsData: {},
            tableHeight: 0,
        };
        this.getAnalyticsDataRequest = this.getAnalyticsDataRequest.bind(this);
        this.setFilteredData = this.setFilteredData.bind(this);
        this.getShortAnalytics = this.getShortAnalytics.bind(this);
        this.checkAuth = this.checkAuth.bind(this);
        this.checkAuth();
        this.getShortAnalytics();
        this.getAnalyticsDataRequest();
    }

    checkAuth(){
        getAuthHeader(getHeaders()).catch(e => {
            this.setState({redirect: '/'})
        }).then(headers => {
            fetch(serverUrl + 'accounts/profile/', {headers: headers, method: 'GET'})
            .then(res => {
                if (res.ok){
                    this.getAnalyticsDataRequest();
                }else{
                    this.setState({redirect: '/'});
                }
            })
        })
    }

    getShortAnalytics(){
        getAuthHeader(getHeaders()).then(headers => {
            fetch(serverUrl + 'get-short-analytics/', {headers: headers, method: 'GET'})
            .then(res => res.json())
            .then(res => {
                const data = {
                    openWithViolations: res.open_with_violations,
                    earlyClosures: res.early_closures,
                    notOpen: res.not_open,
                    geoViolations: res.geo_violations
                };            
                this.setState({shortAnalytics: data});
            })
        });
    }

    setFilteredData(data){
        // Использован фильтр, отображаем только отфильтрованные данные
        this.setState({analyticsData: data})
    }

    getAnalyticsDataRequest(){
        // Получаем данные аналитики
        getAuthHeader(getHeaders).then(headers => {
            fetch(serverUrl + 'get-analytics/', {headers: headers, method: 'GET'})
            .then(res => res.json())
            .then(res => {
                // const analyticsData = res.map((res) => [
                //     res.delivery_point_data.entity,
                //     res.delivery_point_data.town,
                //     res.delivery_point_data.address,
                //     res.date_format,
                //     res.open_time_format,
                //     res.delta_open_time,
                //     res.close_time_format,
                //     res.delta_close_time,
                //     Math.floor(res.delta_open_geo),
                //     Math.floor(res.delta_close_geo),
                //     res.delivery_point_data.rd, 
                //     res.delivery_point_data.currentTPM
                // ]);
                // const analyticsData = res
                this.setState({analyticsData: res});    
            })
        })
    }

    componentDidMount(){
        const tableHeight = document.getElementById('analytics').clientHeight - document.getElementById('analytics-filter').clientHeight - 90 - document.getElementById('analytics-header').clientHeight - document.getElementById('analytics-data').clientHeight;
        this.setState({tableHeight: tableHeight});
        // setInterval(this.getAnalyticsDataRequest, 2.5 * 60 * 1000);
        setInterval(this.getShortAnalytics, 2.5 * 60 * 1000);
    }

    render(){

        const filterElement = <AnalyticsFilter setFilteredData={this.setFilteredData}/>;

        if (this.state.redirect){
            return <Navigate to={this.state.redirect}/>;
        }

        return (
            <div id="analytics" className="page">
                <Menu page="analytics"/>
                <div id="analytics" className="page-content">
                    <div className="header" id="analytics-header">
                        <h2>Аналитика</h2>
                        <AnalyticsPopUp />
                    </div>
                    <div className="content">
                        <AnalyticsData data={this.state.shortAnalytics} />
                        <div className="widget" id="widget-analytics">
                            {filterElement}
                            <AnalyticsTable 
                                keyElementName={''}
                                height={this.state.tableHeight} 
                                headers={['Партнер', 'Город', 'Адрес', 'Дата', 'Время открытия', 
                                    'Отклонение ВО', 'Время закрытия', 'Отклонение ВЗ', 'Отклонение гео (открытия)', 'Отклонение гео (закрытия)' , 'РД', 'ТРМ']} 
                                data={this.state.analyticsData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Analytics;