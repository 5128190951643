import { useState } from "react";

const startRowsDisplayed = 100; 

function TableRow({rowData, keyElementName}){
    return (
        <tr>
            {rowData.map((value) => <td key={value[keyElementName]}>{value}</td>)}
        </tr>
    );
}

function TableHeaders({headers}){
    return (
        <tr className="headers">
            {headers.map((header) => <td key={header}>{header}</td>)}
        </tr>
    );
}

export default function Table({headers, data, height, idTable, keyElementName, addComponent=false}){
    let [rowsDisplayed, setRowsDisplayed] = useState(0);
    // alert(height);
    if (data && Object.keys(data).length > 0){
        return (
            <div style={{'height': height + 'px'}} className="table widget">
                {addComponent && addComponent}
                <table id={idTable}>
                    <TableHeaders headers={headers}/>
                    {
                        data.map((row) => <TableRow keyElementName={keyElementName} rowData={row}/>)                
                    }
                </table>
            </div>
        );
    }else{
        return (
            <div style={{'height': height + 'px'}} className="table widget">
                <span className="not-data-table">Нет данных</span>
            </div>
        );
    }
}