import App from './components/app';
import React from 'react';
import ReactDOM from 'react-dom/client';
// import 'reactjs-popup/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);