import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import CloseModalImg from '../images/close-modal.png';
import { useState } from 'react';

export default function PopUp(props){
    // const [open, setOpen] = useState(false);

    return (
        <div>
            <button onClick={props.setOpen}>{props.buttonText}</button> 
            <Popup className="widget" open={props.open} onClose={props.closeButtonClick} modal={true} position="center center" closeOnDocumentClick={false}>
                <div className="pop-up">
                    <div className="header">
                        <h2>{props.header}</h2>
                        <button onClick={props.setOpen} className="close">
                            <img src={CloseModalImg} />
                        </button>
                    </div>
                    <hr></hr>
                    {props.content}
                    <div className="buttons">
                        <button className="action-button btn" onClick={props.actionButtonClick} >{props.actionButtonText}</button>
                        <button className="close-button btn" onClick={props.setOpen}>Закрыть</button>
                    </div>
                </div>
            </Popup>
        </div>
    );
}