import { isJwtExpired } from 'jwt-check-expiration';

// export const serverUrl = "http://127.0.0.1:8000/";
export const serverUrl = "https://exmail-pvz.tech/api/";

export function getHeaders(){
    return {
        'Content-Type': 'application/json'
    };
}

export function getAuthHeader(headers){
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken){
        if (!isJwtExpired(user.accessToken)){
            return new Promise((resolve, reject) => resolve(Object.assign(headers, {Authorization: 'Bearer ' + user.accessToken})));
        }else{
            if (isJwtExpired(user.refreshToken)){
                return new Promise((resolve, reject) => reject('Refresh token has expired'));
            }else{
                return fetch(serverUrl + 'accounts/token/refresh/', {method: 'POST', 
                    body: JSON.stringify({'refresh': user.refreshToken}),
                    headers: {
                        'Content-Type': 'application/json'
                    }}).then((res) => res.json())
                    .then((res) => {
                        user = JSON.parse(localStorage.getItem('user'));
                        user.accessToken = res['access'];
                        localStorage.setItem('user', JSON.stringify(user));
                        return Object.assign(headers, {Authorization: 'Bearer ' + res['access']});
                    });
            }
        }
    }else{
        return new Promise((resolve, reject) => reject('Not found user'))
    }
}
