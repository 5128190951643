import { useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru)

export default function InputField({data, onChange, refresh}){
    const [startDate, setStartDate] = useState(new Date());

    const calendarChange = (date) => {
        setStartDate(date)
        let e = {
            target: {
                value: date,
            }
        }
        onChange(e);
    }

    if (refresh){
        
    }

    let inputEl = <input type={'text'} onChange={onChange} value={data.value} placeholder={data.placeholder}/>;

    if (data.type == 'select'){
        // Add selected
        const uniqueArray = data.options.filter(function(item, pos) {
            return data.options.indexOf(item) == pos;
        })

        const ruCollator = new Intl.Collator('ru-RU');
        let sortRu = []
        if (data.noSort){
            sortRu = uniqueArray;
        }else{
            sortRu = [...uniqueArray].sort((a, b) => ruCollator.compare(a, b));
        }
        
        let optionsList = sortRu.map((op) => {
            if (data.value){
                if (data.value != op){
                    return <option>{op}</option>;
                }
            }else{
                return <option>{op}</option>;
            }
        });

        if (data.value){
            optionsList.unshift(<option selected>{data.value}</option>);
        }
        
        inputEl = <select onChange={onChange} placeholder={data.placeholder}>{optionsList}</select>;
    }
    if (data.type == 'calendar'){
        inputEl = <DatePicker  dateFormat="P" locale="ru" selected={startDate} onChange={(date) => calendarChange(date)} />
    }
    if(data.type === 'checkbox'){
        inputEl = <input type="checkbox" onChange={onChange}/>
    }

    return (
        <div className={"input-field " + (data.error ? 'error-field' : '')}>
            <span>{data.error ? data.error: data.label + ':'}</span>
            {/* <input type={'text'} onChange={onChange} value={data.value} placeholder={data.placeholder}/> */}
            {inputEl}
        </div>
    );
}