const serverUrl = "https://exmail-pvz.tech/api/";
// const serverUrl = "http://127.0.0.1:8000/";

function getUser(){
    return JSON.parse(localStorage.getItem('user'));
}

function setUser(username, refreshToken, accessToken){
    localStorage.setItem('user', JSON.stringify({
        username: username,
        accessToken: accessToken,
        refreshToken: refreshToken
    }));
}

export function createRequest(url, method, data, succFunc, errFunc, headers={}){
    let options = {
        headers: Object.assign({'Content-Type': 'application/json'}, headers),
        method: method
    }

    if (Object.keys(data).length > 0){
        options.body = JSON.stringify(data);
    }

    fetch(serverUrl + url, options)
    .then((res) => {
        if (!res.ok){ errFunc(res.status); }else{ return res.json(); }
    }).then((res) => {   
        succFunc(res);
    })
}

function refreshJWTToken(succFunc, errFunc){
    const refreshToken = getUser().refreshToken;
    createRequest('accounts/token/refresh/', 'POST', {'refresh': refreshToken}, 
    (res) => {
        const username = getUser().username;
        // setUser(username, refreshToken, res['access']);
        succFunc(res['access']);
    }, (err) => {
        errFunc(err);
    });
}

export function createAuthRequest(url, method, data, succFunc, errFunc){
    const accessToken = getUser().accessToken;

    createRequest(url, method, data, succFunc, 
        (status) => {
            if (status === 401){
                // JWT access token просрочен
                refreshJWTToken((accessToken) => {
                    createRequest(url, method, data, succFunc, errFunc, {Authorization: 'Bearer ' + accessToken})
                }, errFunc);
            }
        }, 
        {Authorization: 'Bearer ' + accessToken}
    );
}

export function login(username, password, succFunc, errFunc){
    createRequest('accounts/token/', 'POST', {username: username, password: password},
        (res) => {
            const user = {
                username: username,
                accessToken: res['access'],
                refreshToken: res['refresh']
            };
            localStorage.setItem('user', JSON.stringify(user));
            succFunc();
    }, errFunc);
}

export function logout(){
    localStorage.removeItem('user');
}

export function checkAuth(succFunc, errFunc){
    if (localStorage.getItem('user') === null){
        errFunc();
    }else{
        createAuthRequest('accounts/profile/', 'GET', {}, succFunc, (err) => {
            logout();
            errFunc(err);
        });  
    }
}
