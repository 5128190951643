import { useState } from "react";
import { getHeaders, getAuthHeader, serverUrl } from "./api-client";
import InputField from "./input-field";

const mounts = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 
                'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

function daysInMonth(month, year){
    return new Date(year, month, 0).getDate();
}

function getDaysList(countDays){
    let list = [];
    for (let index = 0; index < countDays; index++) {
        list.push(index + 1);
    }
    return list;
}

export default function AnalyticsFilter({setFilteredData}){

    const today = new Date();
    
    let filterFormData = {
        isClosed: {label: 'Только не открытые', type: 'checkbox', value: false, validate: () => false},
        town: {label: 'Город', type: 'select', value: 'Все', options: ['Загрузка...'], validate: () => false},
        mount: {label: 'Месяц', type: 'select', noSort: true, value: mounts[today.getMonth()], options: mounts, validate: () => false},
        date: {label: 'Число', type: 'select', noSort: true, value: today.getDate(), options: getDaysList(daysInMonth(today.getMonth() + 1, today.getFullYear())), validate: () => false},
        TPM: {label: 'ТРМ', type: 'select', value: 'Все', options: ['Загрузка...'], validate: () => false},
        rd: {label: 'РД', type: 'select', value: 'Все', options: ['Загрузка...'], validate: () => false},
        openTimeMin: {placeholder: '', value: '0', label: 'От', type: 'text', 
            validate: (v) => /^-{0,1}\d+$/.test(v) ? '': 'Это число'},
        openTimeMax: {placeholder: '', value: '0', label: 'До', type: 'text', 
            validate: (v) => /^-{0,1}\d+$/.test(v) ? '': 'Это число'},
        closeTimeMin: {placeholder: '', value: '0', label: 'От', type: 'text', 
            validate: (v) => /^-{0,1}\d+$/.test(v) ? '': 'Это число'},
        closeTimeMax: {placeholder: '', value: '0', label: 'До', type: 'text', 
            validate: (v) => /^-{0,1}\d+$/.test(v) ? '': 'Это число'},
        geoMin: {placeholder: '', value: '0', label: 'От', type: 'text', 
            validate: (v) => /^-{0,1}\d+$/.test(v) ? '': 'Это число'},
        geoMax: {placeholder: '', value: '0', label: 'До', type: 'text', 
            validate: (v) => /^-{0,1}\d+$/.test(v) ? '': 'Это число'},
    };

    Object.keys(filterFormData).forEach(key => {
        if (!filterFormData[key].value){
            filterFormData[key].value = '';
        }
        filterFormData[key].error = '';
    });

    const [data, setData] = useState(filterFormData)
    const [refresh, setRefresh] = useState(false);

    if (data === filterFormData){
        getAuthHeader(getHeaders()).then(headers => {
            fetch(serverUrl + 'get-filter-options/', {method: 'GET', headers: headers})
            .then(res => res.json())
            .then(res => {
                let formData = Object.assign({}, data);
                res.town_list.unshift('Все');
                res.tpm_list.unshift('Все');
                res.rd_list.unshift('Все');
    
                formData.town.options = res.town_list;
                formData.TPM.options = res.tpm_list;
                formData.rd.options = res.rd_list;
                
                setData(formData);
            })
        });
    }

    const validate = () => {
        let formDataLocal = data;
        let isValid = true
        Object.keys(formDataLocal).forEach(key => {
            formDataLocal[key].error = '';
            const v = formDataLocal[key].value
            if (formDataLocal[key].validate(v)){
                isValid = false
                formDataLocal[key].error = formDataLocal[key].validate(v)
            }
            setData(formDataLocal)
            setRefresh(!refresh)
        });
        return isValid
    }

    const setFilterClick = (e) => {
        if (!validate())
            return ;
        getAuthHeader(getHeaders()).then(headers => {
            fetch(serverUrl + 'get-analytics/', {headers: headers, method: 'POST',
                body: JSON.stringify({
                    is_closed: data.isClosed.value,
                    month: data.mount.value, 
                    TPM: data.TPM.value, 
                    rd: data.rd.value, 
                    date: data.date.value, 
                    town: data.town.value,
                    open_time_min: data.openTimeMin.value,
                    open_time_max: data.openTimeMax.value,
                    close_time_min: data.closeTimeMin.value,
                    close_time_max: data.closeTimeMax.value,
                    geo_min: data.geoMin.value,
                    geo_max: data.geoMax.value
                })})
            .then(res => res.json())
            .then(res => {
                // const analyticsData = res.map((res) => [
                //     res.delivery_point_data.entity,
                //     res.delivery_point_data.town,
                //     res.delivery_point_data.address,
                //     res.date_format,
                //     res.open_time_format,
                //     res.delta_open_time,
                //     res.close_time_format,
                //     res.delta_close_time,
                //     Math.floor(res.delta_open_geo),
                //     Math.floor(res.delta_close_geo),
                //     res.delivery_point_data.rd, 
                //     res.delivery_point_data.currentTPM,
                // ]);
                setFilteredData(res);
                })
        })
    }

    const setValue = (e, key) => {
        let dataLocal = data
        if (key === 'mount'){
            // let date = dataLocal.date;
            dataLocal.date.options = getDaysList(daysInMonth(mounts.indexOf(e.target.value) + 1, today.getFullYear()))
            dataLocal.date.value = 1;
        }
        if (key === 'isClosed'){
            dataLocal[key].value = e.target.checked
        }else{
            dataLocal[key].value = e.target.value
        }
        setData(dataLocal)
        setRefresh(!refresh);
    };

    return (
        <div className="analytics-filter" id="analytics-filter">
            <form>
                <div className="top-form">
                    <InputField data={data.town} onChange={e => setValue(e, 'town')}/>
                    <InputField data={data.mount} onChange={e => setValue(e, 'mount')}/>
                    <InputField refresh={refresh} data={data.date} onChange={e => setValue(e, 'date')}/>
                    <InputField data={data.TPM} onChange={e => setValue(e, 'TPM')}/>
                    <InputField data={data.rd} onChange={e => setValue(e, 'rd')}/>
                    <InputField data={data.isClosed} onChange={e => setValue(e, 'isClosed')}/>
                </div>
                <div className="bottom-form">
                    <div>
                        <span>Отклонение по открытию</span>
                        <div className="bottom-form-container">
                            <InputField refresh={refresh} data={data.openTimeMin} onChange={e => setValue(e, 'openTimeMin')}/>
                            <InputField refresh={refresh} data={data.openTimeMax} onChange={e => setValue(e, 'openTimeMax')}/>
                        </div>
                    </div>
                    <div>
                        <span>Отклонение по закрытию</span>
                        <div className="bottom-form-container">
                            <InputField refresh={refresh} data={data.closeTimeMin} onChange={e => setValue(e, 'closeTimeMin')}/>
                            <InputField refresh={refresh} data={data.closeTimeMax} onChange={e => setValue(e, 'closeTimeMax')}/>
                        </div>
                    </div>
                    <div>
                        <span>Отклонение по гео-точке</span>
                        <div className="bottom-form-container">
                            <InputField refresh={refresh} data={data.geoMin} onChange={e => setValue(e, 'geoMin')}/>
                            <InputField refresh={refresh} data={data.geoMax} onChange={e => setValue(e, 'geoMax')}/>
                        </div>
                    </div>
                </div>
            </form>
            <button className="btn" onClick={setFilterClick}>Применить</button>
        </div>
    );
}